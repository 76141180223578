import axios from "axios";

const chat = {
  namespaced: true,
  state: () => ({
    chats: [],
    currentChatDetails: {},
    currentChatMessages: [],
    currentChatUsers: [],
    currentChatTyperIds: [],
    messagesLimit: 20,
    selectedChatId: "",
    showChatFeed: false,
  }),
  mutations: {
    setChats(state, chats) {
      state.chats = chats;
    },
    setCurrentChatMessages(state, messages) {
      state.currentChatMessages = messages;
    },
    addMessage(state, messageData) {
      state.currentChatMessages.push(messageData);
    },
    setMessagesLimit(state, limit) {
      state.messagesLimit = limit;
    },
    setCurrentChatUsers(state, users) {
      state.currentChatUsers = users;
    },
    setCurrentChatTyperIds(state, users) {
      state.currentChatTyperIds = users;
    },
    setCurrentChatDetails(state, data) {
      state.currentChatDetails = data;
    },
    setShowChatFeed(state, data) {
      state.showChatFeed = data;
    },
    setSelectedChatId(state, id) {
      state.selectedChatId = id;
    },
  },
  actions: {
    getChats({ commit }) {
      return axios
        .get("/conversations.json")
        .then(function (response) {
          const chats = response.data.all_conversations;

          commit("setChats", chats);

          return response;
        })
        .catch(function (error) {
          return error.response;
        });
    },
    createChat(_, chatTitle) {
      return axios
        .post("/conversations", {
          conversation: {
            name: chatTitle,
          },
        })
        .then(function (response) {
          console.log(response);

          return response;
        });
    },
    getChat({ state, commit }, id) {
      const messagesLimit = state.messagesLimit;

      return axios.get(`/conversations/${id}/messages.json?limit=${messagesLimit}`).then((response) => {
        commit("setCurrentChatUsers", response.data.users);
        commit("setCurrentChatTyperIds", response.data.is_typing_user_ids);
        commit("setCurrentChatDetails", response.data.conversation);
        commit("setCurrentChatMessages", response.data.messages);

        return response;
      });
    },
    openChatFeed({ commit }, chatId) {
      commit("setSelectedChatId", chatId);
      commit("setShowChatFeed", true);
    },
    closeChatFeed({ commit }) {
      commit("setSelectedChatId", "");
      commit("setShowChatFeed", false);
      commit("setCurrentChatUsers", []);
      commit("setCurrentChatTyperIds", []);
      commit("setCurrentChatDetails", {});
      commit("setCurrentChatMessages", []);
    },
    sendMessage({ state, commit }, data) {
      const conversationId = state.currentChatDetails.id;

      return axios
        .post(`/conversations/${conversationId}/messages.json`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          commit("addMessage", response.data);

          return response;
        });
    },
    incrementMessagesLimit({ state, commit }) {
      let newMessagesLimit = state.messagesLimit + 20;
      commit("setMessagesLimit", newMessagesLimit);
    },
    resetMessagesLimit({ commit }) {
      commit("setMessagesLimit", 20);
    },
    updateUserIsTyping({ state }, userIsTyping) {
      let conversationId = state.currentChatDetails.id;

      axios.post(`/conversations/${conversationId}/messages.json`, {
        is_typing: userIsTyping,
      });
    },
  },
  getters: {
    chats(state) {
      return state.chats;
    },
    currentChatMessages(state) {
      return state.currentChatMessages.map((message, index, messagesArray) => {
        // don't run this code on the first message as it has no previous messages
        if (index > 0) {
          // Set "repeat_message" if this message is from the same user as the previous one and is timestamped within 30 minutes of the previous message
          let previousIndex = index - 1;
          let hideDetails =
            message.from_user_id == messagesArray[previousIndex].from_user_id &&
            Date.parse(message.date) - Date.parse(messagesArray[previousIndex].date) < 30 * 60 * 1000;

          if (hideDetails) {
            message.repeat_message = true;
          } else {
            message.repeat_message = false;
          }
        } else {
          message.repeat_message = false;
        }

        return message;
      });
    },
    currentChatImages(state) {
      return state.currentChatMessages
        .filter((message) => message.attachment_is_image && !message.removed)
        .map((message) => {
          return {
            message_id: message.id,
            image_url: message.attachment_url,
          };
        });
    },
    currentChatUsers(state) {
      return state.currentChatUsers;
    },
    currentChatTyperUsers(state) {
      const currentChatTyperIds = state.currentChatTyperIds;
      const currentChatUsers = state.currentChatUsers;

      // return users that have an id matching any of the ids inside the currentChatTyperIds array
      return currentChatUsers.filter((user) => currentChatTyperIds.includes(user.id));
    },
    messagesLimit(state) {
      return state.messagesLimit;
    },
    currentChatDetails(state) {
      return state.currentChatDetails;
    },
    selectedChatId(state) {
      return state.selectedChatId;
    },
    showChatFeed(state) {
      return state.showChatFeed;
    },
    currentChatUnreadMsgsCount(state) {
      const currentChatId = state.currentChatDetails.id;
      const chatData = state.chats.find((chat) => chat.id === currentChatId);

      if (chatData) {
        const unreadMessagesCount = chatData.unread_message_count;

        if (unreadMessagesCount > 0) {
          let newMessages = state.currentChatMessages.slice(-unreadMessagesCount);
          const newMessagesFromOtherUsers = newMessages.filter((msg) => !msg.from_current_user);

          return newMessagesFromOtherUsers.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
  },
};

export default chat;
