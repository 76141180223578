// import colors from 'vuetify/lib/util/colors'
export default {
  primary: '#363844',
  toolbar: '#D3F8E2',
  purple: '#DEC2F5',
  darkPurple: '#C192E8',
  yellow: '#ECE7B7',
  darkYellow: '#D8CB64',
  blue: '#B4DCF6',
  darkBlue: '#1FADF4',
  pink: '#F694C1',
  darkPink: '#F27DB4',
  green: '#D3F8E2',
  darkGreen: '#67E095',
  white: '#ffffff',
} 