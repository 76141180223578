export default {
    methods: {
      handleContentClick: function (evt) {
        let target = evt.target

        // if v-icon wrapped in link clicked
        if (target.tagName === 'I' && target.parentNode.tagName === 'A' && window.cordova) {
            target = target.parentNode
        }

        if(target.tagName === 'A' && window.cordova) {
            evt.preventDefault();
  
            if (target.href.indexOf("mailto:") > -1){
                // window.open(target.href, '_system');
                let email = target.href.replace("mailto:", "")
                cordova.plugins.email.open({ // eslint-disable-line
                    to: email,
                });
            }
  
            else if (target.href.indexOf("tel:") > -1){
                let number = target.href.replace("tel:", "")
                cordova.plugins.phonedialer.dial(number); // eslint-disable-line
            }
  
            else {
              this.openUrl(target.href);
            }
  
        }
      },
      openUrl(link) {
        //ANDROID
        if (window.device.platform === 'Android') {
          if (link.endsWith(".pdf") || link.endsWith(".docx") || link.endsWith(".doc") || link.endsWith(".xls") || link.endsWith(".xlsx") || link.endsWith(".ppt") || link.endsWith(".pptx")) {
            window.cordova.InAppBrowser.open(link, "_system");
          } else {
            if (link.indexOf("http") > -1){
              window.cordova.InAppBrowser.open(link, "_blank", "toolbarposition=top,closebuttoncolor=#000000,toolbarcolor=#cccccc") // eslint-disable-line
            }
            else {
              window.cordova.InAppBrowser.open("http://" + link, "_blank", "toolbarposition=top,closebuttoncolor=#000000,toolbarcolor=#cccccc") // eslint-disable-line
            }
          }
        }
  
        //iOS - open documents with in app browser works, no location
        else if (window.device.platform === 'iOS') {
          if (link.indexOf("http") > -1){
            window.cordova.InAppBrowser.open(link, "_blank", "toolbarposition=top,closebuttoncolor=#000000,toolbarcolor=#cccccc,location=no") // eslint-disable-line
          }
          else {
            window.cordova.InAppBrowser.open("http://" + link, "_blank", "toolbarposition=top,closebuttoncolor=#000000,toolbarcolor=#cccccc,location=no") // eslint-disable-line
          }
        }
          //in case of any other do window.open
         else {
          window.open(link, '_blank');
         }
      },
      clickLink(link) {
        if(window.cordova) {
          this.openUrl(link);
        } else {
          window.open(link, '_blank')
        }
      }
    }
  }