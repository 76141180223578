import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from "./store";
import store from "./stores/store";
import vuetify from './plugins/vuetify';
// import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import VueTimeago from 'vue-timeago'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueBus from 'vue-bus';  // https://www.npmjs.com/package/vue-bus
import VueTour from 'vue-tour' // https://www.npmjs.com/package/vue-tour
import sharedMethods from './mixins/sharedMethods';

Vue.mixin(sharedMethods);

require('vue-tour/dist/vue-tour.css')

Vue.config.productionTip = false;

// used for user tour
Vue.use(VueTour)

// used for global emits
Vue.use(VueBus);

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  locales: {}
}),

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCDBqQni1hoOZJMwsmHUeZiiUXVYXwp9ic',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
    installComponents: true

})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
