<template>
  <v-app :class="[hubbdata.openDyslexic ? 'open-dyslexic' : '', { 'intro-pages': isIntroPages }]">
    <Header v-if="$route.path != '/welcome' && $route.path != '/welcome-terms-and-conditions'" />

    <v-main>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath" />
      </transition>

      <AppDownload v-if="isMobileDevice && isUsingBrowser" />
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Header/Header";
// import chat from "@/chat";
import hubbdata from "@/hubbdata";
import AppDownload from "@/components/AppDownload";
import { isMobile } from "mobile-device-detect";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    Header,
    AppDownload,
  },
  watch: {
    // '$route': 'currentRoute',
    $route: {
      immediate: true,
      handler(to) {
        console.log(to);
        if (to && to.name) {
          document.title = to.name.replace(/_|-|\./g, " ") || "App";
        } else {
          document.title = "App";
        }
      },
    },
  },
  data: () => ({
    routeName: null,
    alert: false,
    openDyslexic: false,
    dialog: true,
  }),
  methods: {
    ...mapActions("user", ["startPollingUserData"]),
    currentRoute() {
      this.$nextTick(() => {
        this.routeName = this.$route.name;
      });
    },
  },
  mounted() {
    if (localStorage.getItem("current_lang") !== null) {
      hubbdata.state.current_lang = localStorage.getItem("current_lang");
    } else {
      hubbdata.state.current_lang = "en";
    }

    hubbdata.dispatch("initDB");
    hubbdata.dispatch("setFont");

    if (this.isTokenSet) {
      this.startPollingUserData();
    }
  },
  computed: {
    ...mapGetters("user", ["isTokenSet"]),
    current_users() {
      // return chat.state.users;
      return [];
    },
    translations() {
      return hubbdata.getters.translations;
    },
    hubbdata() {
      return hubbdata.state;
    },
    isMobileDevice() {
      return isMobile;
    },
    isUsingBrowser() {
      if (window.cordova) {
        return false;
      } else {
        return true;
      }
    },
    isIntroPages() {
      return this.$route.name == "welcome" || this.$route.name == "terms" ? true : false;
    },
  },
};
</script>

<style lang="scss">
.cms-content {
  color: $primary;

  a {
    color: $primary !important;
  }

  &--white {
    color: $white;

    a {
      color: $white !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "ff-cocon-pro", sans-serif;
    font-weight: 400;
    margin-bottom: 16px;
  }
}

.border .v-main {
  margin-bottom: 0px !important;
  border: solid 6px $primary;
}

.fade-enter-active {
  transition: opacity 0.2s ease 0.2s;
}

.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
// a {
// color: #0062ae !important;
// text-decoration: underline !important;
// }

*:focus {
  outline: #0062ae solid 3px;
}

// a:focus {
//   outline: auto;
// }

.v-list-item__content .v-list-item__content:focus-within,
.v-list--two-line .v-list-item__content:focus-within,
.icons:focus .v-card {
  outline: #0062ae solid 3px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  color: black !important;
  background-color: white;
}

////// APP TOUR PLUGIN /////
.v-step {
  background-color: $white !important;
  max-width: 280px !important;

  &__header {
    border-bottom: 3px solid $darkGreen;
    font-size: 18px;
    background-color: $white !important;
    color: $primary;
    font-family: "ff-cocon-pro", sans-serif !important;
    font-weight: 400 !important;

    div {
      font-family: inherit;
      font-weight: inherit;
    }
  }

  &__content {
    color: $primary;
  }

  &__arrow {
    &--dark {
      &:before {
        background: $darkGreen !important;
      }
    }
  }

  &__button {
    border: 0.05rem solid $primary !important;
    color: $primary !important;
    font-size: 14px !important;
    font-family: "ff-cocon-pro", sans-serif !important;
    font-weight: 400 !important;
    padding: 0.35rem 0.75rem !important;

    &:hover,
    &:focus {
      background-color: $primary !important;
      color: $white !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.v-dialog__content {
  @media (min-width: 960px) {
    padding-left: 256px;
  }
}

.v-dialog {
  &__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.google-play-img,
.apple-store-img {
  width: 180px;
  margin: 0 auto;

  @media (min-width: 600px) {
    height: 50px;
    width: auto;
  }
}
</style>
