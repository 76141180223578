import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router";

axios.defaults.baseURL = process.env.API_URL || "https://ealing.secure-messaging.co.uk";

// POST with JSON content type
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem("token");

  config.headers.authorization = token ? token : "";

  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // if any request is 401 and the user token is set meaning they are logged in then we logout the user and reroute them
    if (error.response.status == 401 && store.getters["user/isTokenSet"]) {
      store.dispatch("user/logoutUser").then(() => {
        // if user is on any of the DCP pages reroute them to the sign in page
        if (
          router.currentRoute.name === "user_edit" ||
          router.currentRoute.name === "forgot_password" ||
          router.currentRoute.name === "chats" ||
          router.currentRoute.name === "posts" ||
          router.currentRoute.name === "surveys"
        ) {
          router.push({ name: "sign_in" });
        }
      });
    }
    return Promise.reject(error);
  }
);

import user from "./user";
import post from "./post";
import group from "./group";
import chat from "./chat";
import survey from "./survey";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    post,
    group,
    chat,
    survey,
  },
  state: {
    loaderStatus: {
      visability: false,
      text: "",
    },
  },
  mutations: {
    setloaderStatus(state, loaderStatusData) {
      state.loaderStatus = loaderStatusData;
    },
  },
  actions: {
    updateLoaderStatus({ commit }, loaderData) {
      commit("setloaderStatus", {
        visability: loaderData.visability,
        text: loaderData.text,
      });
    },
  },
  getters: {
    loaderStatus(state) {
      return state.loaderStatus;
    },
  },
});

export default store;
