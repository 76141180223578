import axios from "axios";

const survey = {
    namespaced: true,
    state: () => ({
        surveys: [],
        shareableSurveyId: null,
        currentSurveyId: null,
        currentSurvey: {},
        currentSurveyAnswers: {},
        showSurvey: false
    }),
    mutations: {
        setSurveys(state, surveys) {
            state.surveys = surveys;
        },
        setShareableSurveyId(state, id) {
            state.shareableSurveyId = id;
        },
        setCurrentSurveyId(state, id) {
            state.currentSurveyId = id;
        },
        setShowSurvey(state, bool) {
            state.showSurvey = bool;
        },
        setCurrentSurvey(state, surveyData) {
            state.currentSurvey = surveyData;
        },
        setCurrentSurveyAnswers(state, answers) {
            state.currentSurveyAnswers = answers;
        }
    },
    actions: {
        getSurveys({ commit }, searchValue) {
            const parameters = {}

            if (searchValue) {
                parameters.search = searchValue;
            }

            return axios.get('/surveys.json', {params: parameters})
                .then(function(response) {
                    const data = response.data;
                    const surveys = data.surveys;

                    commit('setSurveys', surveys);
                    return response;
                })
                .catch(function(error) {
                    return error.response;
                })
        },
        saveShareableSurveyId({ commit }, id) {
            commit('setShareableSurveyId', id);
        },
        clearShareableSurveyId({ commit }) {
            commit('setShareableSurveyId', null);
        },
        openSurvey({commit}, id) {
            commit('setCurrentSurveyId', id);
            commit('setShowSurvey', true);
        },
        closeSurvey({commit}) {
            commit('setCurrentSurveyId', null);
            commit('setCurrentSurveyAnswers', {});
            commit('setShowSurvey', false);
        },
        getSurvey({ commit }, id) {
            return axios.get(`/surveys/${id}/answers/edit.json`)
                .then((response) => {
                    let data = response.data;

                    commit('setCurrentSurvey', data.survey);
                    commit('setCurrentSurveyAnswers', data.answers);

                    return response;
                })
        },
        updateAnswer({ state, commit }, { question_id, answer }) {
            let answers = state.currentSurveyAnswers;
            let clonedAnswers = JSON.parse(JSON.stringify(answers));

            clonedAnswers[`${question_id}`]['answer'] = answer;

            commit('setCurrentSurveyAnswers', clonedAnswers);
        },
        updateFurtherComment({ state, commit }, { question_id, comment }) {
            let answers = state.currentSurveyAnswers;
            let clonedAnswers = JSON.parse(JSON.stringify(answers));

            clonedAnswers[`${question_id}`]['further_comments'] = comment;

            commit('setCurrentSurveyAnswers', clonedAnswers);
        },
        submitSurveyAnswers({ state }, surveyId) {
            let answers = state.currentSurveyAnswers;

            return axios.post(`/surveys/${surveyId}/answers.json`, { answers: answers })
                .then((response) => {
                    console.log(response);
                    return response
                })
        }
    },
    getters: {
        surveys(state) {
            return state.surveys;
        },
        shareableSurvey(state) {
            return state.surveys.find(survey => survey.id === state.shareableSurveyId);
        },
        currentSurveyId(state) {
            return state.currentSurveyId;
        },
        showSurvey(state) {
            return state.showSurvey;
        },
        currentSurvey(state) {
            return state.currentSurvey;
        },
        answer: (state) => (id) => {
            return state.currentSurveyAnswers[`${id}`];
        },
        surveysAmount(state) {
            return state.surveys.length;
        }
    },
}

export default survey;