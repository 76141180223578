<template>
  <v-dialog v-model="dialog" width="460" class="" hide-overlay>
    <v-card class="pa-4 pa-sm-6">
      <v-btn
        @click="closeModal"
        icon
        :elevation="12"
        color="white"
        aria-label="Close Modal"
        class="v-dialog__close red"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-img src="@/assets/ealing/images/ealing-logo.png" alt="" contain width="70px" class="mx-auto mb-4" />

      <h2 class="font-family-roc-grotesk font-weight-regular text-body black--text text-center mb-4">
        The Ealing Cares App is available for download
      </h2>

      <v-row class="justify-space-between">
        <v-col cols="12" sm="6">
          <a
            href="https://play.google.com/store/apps/details?id=uk.co.thisisfocus.claealing"
            target="_blank"
            aria-label="Google Play App"
            @click="handleContentClick($event)"
          >
            <v-img contain src="@/assets/ealing/images/google-play.png" alt="google play" class="google-play-img" />
          </a>
        </v-col>

        <v-col cols="12" sm="6" class="pt-0 pt-sm-3">
          <a
            href="https://apps.apple.com/gb/app/ealing-cares/id1660919928"
            target="_blank"
            aria-label="Apple Store App"
            @click="handleContentClick($event)"
          >
            <v-img contain src="@/assets/ealing/images/apple-store.png" alt="" class="apple-store-img" />
          </a>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import hubbdata from "@/hubbdata";

export default {
  name: "AppDownload",
  data: () => ({
    dialog: true,
  }),
  computed: {
    translations() {
      return hubbdata.getters.translations;
    },
  },
  methods: {
    closeModal() {
      sessionStorage.setItem("AppDownloadShown", true);
      this.dialog = false;
    },
  },
  mounted() {
    let showAppDownload = sessionStorage.getItem("AppDownloadShown");

    if (showAppDownload) {
      this.dialog = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  z-index: 99999 !important;
  @media (min-width: 960px) {
    padding-left: 256px;
  }
}

.intro-pages {
  .v-dialog__content {
    @media (min-width: 960px) {
      padding-left: 0;
    }
  }
}

.v-dialog {
  &__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.google-play-img,
.apple-store-img {
  width: 180px;
  margin: 0 auto;

  @media (min-width: 600px) {
    height: 50px;
    width: auto;
  }
}
</style>
