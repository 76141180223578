import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';

const axiosHub = axios.create();

import initial_db_en from './assets/data/en.json';// eslint-disable-line
import initial_db_sq from './assets/data/sq.json';// eslint-disable-line
import initial_db_am from './assets/data/am.json';// eslint-disable-line
import initial_db_ar from './assets/data/ar.json';// eslint-disable-line
import initial_db_ku from './assets/data/ku.json';// eslint-disable-line
import initial_db_ckb from './assets/data/ckb.json';// eslint-disable-line
import initial_db_om from './assets/data/om.json';// eslint-disable-line
import initial_db_ps from './assets/data/ps.json';// eslint-disable-line
import initial_db_fa from './assets/data/fa.json';// eslint-disable-line
import initial_db_pa from './assets/data/pa.json';// eslint-disable-line
import initial_db_ru from './assets/data/ru.json';// eslint-disable-line
import initial_db_so from './assets/data/so.json';// eslint-disable-line
import initial_db_ti from './assets/data/ti.json';// eslint-disable-line
import initial_db_ur from './assets/data/ur.json';// eslint-disable-line
import initial_db_vi from './assets/data/vi.json';// eslint-disable-line

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    last_fetch: null,
    last_update: null,
    config: {},
    app_blocks: [],
    cms_pages: [],
    events: [],
    organisations: [],
    categories: [],
    links: [],
    opportunities: [],
    surveys: [],
    survey_questions: [],
    documents: [],
    connected: navigator.onLine,
    current_lang: "",
    last_remote_update: undefined,
    current_cms_page: "",
    openDyslexic: false,
  },

  getters: {
    // currentConversation: state => state.current_conversation,
    translations: state => {
      return state.config.translations
    },
    contact: state => {
      return state.config.contact
    },
    rootCmsPages: state => {
      return state.cms_pages.filter(cms_page => cms_page.parent_id == "cms_page_")
    },
    getCmsPageById: (state) => (cms_page_id) => {
      var page = state.cms_pages.find(cms_page => cms_page.id === cms_page_id)
      state.current_cms_page = page.page_title
      return page
    },
    getCmsPageByPath: (state) => (path) => {
      var page = state.cms_pages.find(cms_page => cms_page.path === path)
      state.current_cms_page = page.page_title
      return page
    },
    getCmsPageByCategory: (state) => (category_id) => {
      return state.cms_pages.find(cms_page => cms_page.category_ids.indexOf(category_id) > -1)
    },
    getOrganisationsByCategory: (state) => (category_id) => {
      return state.organisations.filter(organisations => organisations.category_ids.indexOf(category_id) > -1)
    },
    getDocumentsByCategory: (state) => (category_id) => {
      return state.documents.filter(documents => documents.category_ids.indexOf(category_id) > -1)
    },
    getLinksByCategory: (state) => (category_id) => {
      return state.links.filter(links => links.category_ids.indexOf(category_id) > -1)
    },
    getOrganisationsById: (state) => (organisations_id) => {
      return state.organisations.find(organisations => organisations.id === organisations_id)
    },
    getOpportunityById: (state) => (opportunity_id) => {
      return state.opportunities.find(opportunity => opportunity.id === opportunity_id)
    },
    getEventById: (state) => (events_id) => {
      return state.events.find(events => events .id === events_id)
    },
    getCategoryPageById: (state) => (category_id) => {
      return state.categories.find(categories => categories.id === category_id)
    },
    getCategoriesPageByCategory: (state) => (category_id) => {
      let id = category_id.replace( /^\D+/g, '')
      return state.categories.filter(categories => categories.ancestry === id)
    },
    getCmsPagesByParentId: (state) => (parent_id) => {
      return state.cms_pages.filter(cms_page => cms_page.parent_id == parent_id)
    },
    getRootCategories: (state) => {
      return state.categories.filter(categories => categories.ancestry == null)
    },



  },
  mutations: {
    setLastUpdate(state, last_update) {
      state.last_update = last_update;
    },
    setLastFetch(state, last_fetch) {
      state.last_fetch = last_fetch;
    },
    setConfig(state, config) {
      state.config = config;
    },
    setAppBlocks(state, app_blocks) {
      state.app_blocks = app_blocks;
    },
    setCmsPages(state, cms_pages) {
      state.cms_pages = cms_pages;
    },
    setOrganisations(state, organisations) {
      state.organisations = organisations;
    },
    setCategories(state, categories) {
      state.categories = categories;
    },
    setLinks(state, links) {
      state.links = links;
    },
    setDocuments(state, documents) {
      state.documents = documents;
    },
    setSurveys(state, surveys) {
      state.surveys = surveys;
    },
    setSurveyQuestions(state, survey_questions) {
      state.survey_questions = survey_questions;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setOpportunities(state, opportunities) {
      state.opportunities = opportunities;
    },
    setLang(state, selectedLang) {
      localStorage.setItem('current_lang', selectedLang)
      this.state.current_lang = selectedLang
      //to set html language
      const html = document.documentElement
      html.setAttribute('lang', this.state.current_lang)
    },

    setFont(state, font) {
      this.state.openDyslexic = font
    },

    addAnswer(state, answer) {
      state.answers.push({
        answer: answer.answer,
        questions_id: answer
      });
    },
  },

  mounted() {
    console.log("created")
    if (localStorage.getItem('current_lang') !== null) {
      this.state.current_lang = localStorage.getItem('current_lang')
    }
    else {
      this.state.current_lang = "en"
    }


  },
  actions: {
    updateLang({ commit },selectedLang) {
      commit("setLastFetch", null);
      commit("setLang", selectedLang);
    },
    updateFont({ commit }, font) {
      commit("setFont", font);
    },
    addAnswer({ commit },answer) {
      commit("addAnswer", answer);
    },
    submitAnswers(thing, data) {
      console.log(data)
      // api.saveSurveyAnswers(data)
    },
    setFont() {
      if (localStorage.getItem('enable_font_change') == "true" ) {
        this.state.openDyslexic = true
        console.log("open dyslexic font set to true")
      }
      else {
        this.state.openDyslexic = false
        console.log("open dyslexic font set to false")

      }
    },
    //-------------------
    // For the DB
    initDB({dispatch}) {
      console.log('current language' + " " + this.state.current_lang)
      // Lets check if they have loaded the database recently
      if (this.state.last_fetch !== null && (Date.now() - this.state.last_fetch < 30000)) {
        // // if they have we just get the store data
        // dispatch('getStoredData')
        console.log('user has recently loaded the database')
      }
      else if (this.state.last_fetch === null) {
        // Looks like it might be the first time using this database for speed lets get it loaclly
        console.log('date of last db update not found')
        dispatch('getLocalDB').then(() => {
          dispatch('getLiveDB');
        })
      }
      else {
        // Lets get the live DB
        // dispatch('getStoredData').then(() => {
          console.log('last update longer than 30 seconds ago')
          dispatch('getLiveDB');
        // });

        // dispatch('getLocalDB')
      }
    },
    // Action for getting local data
    getLocalDB({dispatch}) {
        console.log("Loading local database");

        let response;

        if (this.state.current_lang == "en") {
          response = { data: initial_db_en }
        } else if (this.state.current_lang == "sq") {
          response = { data: initial_db_sq }
        } else if (this.state.current_lang == "am") {
          response = { data: initial_db_am }
        } else if (this.state.current_lang == "ar") {
          response = { data: initial_db_ar }
        } else if (this.state.current_lang == "ku") {
          response = { data: initial_db_ku }
        } else if (this.state.current_lang == "ckb") {
          response = { data: initial_db_ckb }
        } else if (this.state.current_lang == "om") {
          response = { data: initial_db_om }
        } else if (this.state.current_lang == "ps") {
          response = { data: initial_db_ps }
        } else if (this.state.current_lang == "fa") {
          response = { data: initial_db_fa }
        } else if (this.state.current_lang == "pa") {
          response = { data: initial_db_pa }
        } else if (this.state.current_lang == "ru") {
          response = { data: initial_db_ru }
        } else if (this.state.current_lang == "so") {
          response = { data: initial_db_so }
        } else if (this.state.current_lang == "ti") {
          response = { data: initial_db_ti }
        } else if (this.state.current_lang == "ur") {
          response = { data: initial_db_ur }
        } else if (this.state.current_lang == "vi") {
          response = { data: initial_db_vi }
        }

        dispatch('loadDB', response);
    },
    // Action for getting live data
    getLiveDB({commit, dispatch}) {
      console.log("Loading remote database");
      console.log(this.state.current_lang)
      let requested_lag = this.state.current_lang
      if (this.state.current_lang !== "") {
        axiosHub.get('https://ealing.careleaversapp.thisisfocus.co.uk/api/app_feed/' + this.state.current_lang + '.json', {
          auth: {
              username: 'appfeed',
              password: 'D$TQ9Hb7AvTrwkhx'
          },

        })
        .then((response) => {
            if (requested_lag == this.state.current_lang) {
              console.log("Current lang! Lets do it!")
              dispatch('loadDB', response)
              commit('setLastFetch', Date.now());
            }
            else {
              console.log("Looks we have changed lang, aborting!")
            }
        })
        .catch(err => {
          console.log(err);
          alert('Error has occurred getting live data. Data shown may not be accurate');
          // Looks like we are not online, lets get data from the local DB
            dispatch('getLocalDB')
        })
      }
    },
    // Loading the databse
    loadDB({commit}, response) {
      commit("setLastUpdate", response.data.last_update);
      commit("setConfig", response.data.config);
      commit("setAppBlocks", response.data.app_blocks.data);
      commit("setCmsPages", response.data.cms_pages.data);
      commit("setOrganisations", response.data.organisations.data);
      commit("setCategories", response.data.categories.data);
      commit("setLinks", response.data.tiles.data);
      commit("setDocuments", response.data.documents.data);
      commit("setSurveys", response.data.simple_surveys.data);
      commit("setEvents", response.data.events.data);

      if (response.data.opportunities) {
        commit("setOpportunities", response.data.opportunities.data);
      }
    }
  }
});
