import Vue from "vue";
import VueRouter from "vue-router";
// import goTo from 'vuetify/es5/services/goto'
// import store from "../store";
import store from "../stores/store";

// import About from '../views/About.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "/sign-in",
    name: "sign_in",
    component: () => import(/* webpackChunkName: "UserSignIn" */ "../views/UserSignIn.vue"),
  },
  {
    path: "/sign-up",
    name: "sign_up",
    component: () => import(/* webpackChunkName: "UserSignUp" */ "../views/UserSignUp.vue"),
  },
  {
    path: "/user/edit",
    name: "user_edit",
    component: () => import(/* webpackChunkName: "UserEdit" */ "../views/UserEdit.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/forgot-password/",
    name: "forgot_password",
    component: () => import(/* webpackChunkName: "ForgotPassword" */ "../views/UserForgotPassword.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/chats",
    name: "chats",
    component: () => import(/* webpackChunkName: "chats" */ "../views/Chats.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/posts",
    name: "posts",
    component: () => import(/* webpackChunkName: "Posts" */ "../views/Posts.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/events",
    name: "events",
    component: () => import(/* webpackChunkName: "Events" */ "../views/Events.vue"),
  },
  {
    path: "/legal/",
    name: "legal",
    component: () => import(/* webpackChunkName: "Legal" */ "../views/Legal.vue"),
  },
  {
    path: "/surveys",
    name: "surveys",
    component: () => import(/* webpackChunkName: "Surveys" */ "../views/Surveys.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/cms_pages/:id",
    name: "cms_page",
    component: () => import(/* webpackChunkName: "CmsPage" */ "../views/CmsPage.vue"),
  },
  {
    path: "/catagories/:id",
    name: "category",
    component: () => import(/* webpackChunkName: "CmsPage" */ "../views/Category.vue"),
  },
  {
    path: "/organisations/:id",
    name: "organisation",
    component: () => import(/* webpackChunkName: "CmsPage" */ "../views/Organisation.vue"),
  },
  {
    path: "/event/:id",
    name: "event",
    component: () => import(/* webpackChunkName: "CmsPage" */ "../views/Event.vue"),
  },
  {
    path: "/organisations/",
    name: "organisations",
    component: () => import(/* webpackChunkName: "CmsPage" */ "../views/Organisations.vue"),
  },
  {
    path: "/contact/",
    name: "contact",
    component: () => import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
  },
  {
    path: "/welcome/",
    name: "welcome",
    component: () => import(/* webpackChunkName: "Welcome" */ "../views/Welcome.vue"),
  },
  {
    path: "/welcome-terms-and-conditions/",
    name: "terms",
    component: () => import(/* webpackChunkName: "Terms" */ "../views/Terms.vue"),
  },
  {
    path: "/terms-and-conditions/",
    name: "app_terms",
    component: () => import(/* webpackChunkName: "Terms" */ "../views/TermsInApp.vue"),
  },
  {
    path: "/privacy/",
    name: "app_privacy",
    component: () => import(/* webpackChunkName: "Search" */ "../views/PrivacyInApp.vue"),
  },
  {
    path: "/accessibility/",
    name: "app_accessibility",
    component: () => import(/* webpackChunkName: "Search" */ "../views/AccessibilityInApp.vue"),
  },
  {
    path: "/app-search/",
    name: "search",
    component: () => import(/* webpackChunkName: "Search" */ "../views/AppSearch.vue"),
  },
  {
    path: "/favourites",
    name: "favourites",
    component: () => import(/* webpackChunkName: "Search" */ "../views/Favourites.vue"),
  },
  {
    path: "/vips",
    name: "vips",
    component: () => import(/* webpackChunkName: "Search" */ "../views/Vips.vue"),
  },
  {
    path: "/infoandadvice",
    name: "infoandadvice",
    component: () => import(/* webpackChunkName: "Search" */ "../views/InfoAndAdvice.vue"),
  },
  {
    path: "/live-apprenticeships",
    name: "opportunities",
    component: () => import(/* webpackChunkName: "Search" */ "../views/Opportunities.vue"),
  },
  {
    path: "/live-apprenticeship/:id",
    name: "Opportunity",
    component: () => import(/* webpackChunkName: "Search" */ "../views/Opportunity.vue"),
  },
  {
    path: "/education-training-work",
    name: "education_training_work",
    component: () => import(/* webpackChunkName: "Search" */ "../views/EducationTrainingWork.vue"),
  },
  {
    path: "*",
    name: "not_found",
    component: () => import(/* webpackChunkName: "Search" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      console.log(savedPosition.y);
      setTimeout(function () {
        console.log("scrolling!!");
        window.scroll(0, savedPosition.y);
      }, 50);
      return;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

function requireAuth(to, from, next) {
  if (store.getters["user/isTokenSet"]) {
    next();
  } else {
    next({
      path: "/sign-in",
      query: { redirect: to.name },
    });
  }
}

export default router;
