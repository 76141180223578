<template>
  <div v-if="hubbdata">
    <v-app-bar app class="toolbar text--black" light>
      <v-app-bar-nav-icon
        @click="toggleDrawer"
        id="menu-btn"
        class="hidden-md-and-up"
        :aria-label="hubbdata.accessibility ? hubbdata.accessibility.openMenu : ''"
        data-cypress-value="menu"
      >
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
          <title>Menu Icon</title>
          <path
            d="M4.20051 0.313162C1.94361 0.313162 0.107727 2.14917 0.107727 4.40594C0.107727 6.66271 1.94285 8.49859 4.20051 8.49859C6.45816 8.49859 8.29328 6.66271 8.29328 4.40594C8.29328 2.14917 6.45727 0.313162 4.20051 0.313162Z"
            fill="#363844"
          />
          <path
            d="M17.4467 0.313162C15.19 0.313162 13.3539 2.14917 13.3539 4.40594C13.3539 6.66271 15.19 8.49859 17.4467 8.49859C19.7036 8.49859 21.5399 6.66271 21.5399 4.40594C21.5399 2.14917 19.7044 0.313162 17.4467 0.313162Z"
            fill="#363844"
          />
          <path
            d="M30.9072 8.49872C33.1636 8.49872 35 6.66283 35 4.40606C35 2.1493 33.1651 0.313162 30.9072 0.313162C28.6493 0.313162 26.8145 2.14917 26.8145 4.40594C26.8145 6.66271 28.6507 8.49872 30.9072 8.49872Z"
            fill="#363844"
          />
          <path
            d="M4.09354 21.6132C6.35031 21.6132 8.1867 19.7777 8.1867 17.5206C8.1867 15.2633 6.35031 13.4274 4.09354 13.4274C1.83677 13.4274 0 15.2634 0 17.5206C0 19.7777 1.83677 21.6132 4.09354 21.6132Z"
            fill="#363844"
          />
          <path
            d="M17.3397 21.6132C19.5961 21.6132 21.4325 19.7777 21.4325 17.5206C21.4325 15.2633 19.5976 13.4274 17.3397 13.4274C15.0822 13.4274 13.2469 15.2633 13.2469 17.5206C13.2469 19.7777 15.083 21.6132 17.3397 21.6132Z"
            fill="#363844"
          />
          <path
            d="M30.8014 21.6132C33.0587 21.6132 34.8942 19.7777 34.8942 17.5206C34.8942 15.2633 33.0587 13.4274 30.8014 13.4274C28.5441 13.4274 26.7071 15.2633 26.7071 17.5206C26.7071 19.7777 28.5435 21.6132 30.8014 21.6132Z"
            fill="#363844"
          />
          <path
            d="M4.18717 26.5013C1.9304 26.5013 0.0936279 28.3368 0.0936279 30.5941C0.0936279 32.8505 1.9304 34.6867 4.18717 34.6867C6.44393 34.6867 8.27982 32.8513 8.27982 30.5941C8.27982 28.3368 6.44393 26.5013 4.18717 26.5013Z"
            fill="#363844"
          />
          <path
            d="M17.4342 26.5013C15.1773 26.5013 13.3406 28.3368 13.3406 30.5941C13.3406 32.8505 15.1766 34.6867 17.4342 34.6867C19.6906 34.6867 21.5268 32.8513 21.5268 30.5941C21.5268 28.3368 19.6906 26.5013 17.4342 26.5013Z"
            fill="#363844"
          />
          <path
            d="M30.8943 26.5013C28.6379 26.5013 26.8015 28.3368 26.8015 30.5941C26.8015 32.8505 28.6379 34.6867 30.8943 34.6867C33.1516 34.6867 34.9871 32.8513 34.9871 30.5941C34.9871 28.3368 33.1507 26.5013 30.8943 26.5013Z"
            fill="#363844"
          />
        </svg>
      </v-app-bar-nav-icon>

      <v-btn
        @click="$router.go(-1)"
        class="back-button black--text"
        :aria-label="hubbdata.accessibility ? hubbdata.accessibility.back : ''"
        outlined
        v-if="
          $route.name === 'category' ||
          $route.name === 'organisation' ||
          $route.name === 'event' ||
          $route.name === 'cms_page' ||
          $route.name === 'search' ||
          $route.name === 'Opportunity' ||
          $route.name === 'user_sign_in' ||
          $route.name === 'user_edit' ||
          $route.name === 'about' ||
          $route.name === 'app_accessibility' ||
          $route.name === 'app_terms' ||
          $route.name === 'app_privacy'
        "
      >
        <v-icon left> mdi-chevron-left </v-icon>
        {{ hubbdata.tutorial.buttons.back }}
      </v-btn>

      <div>
        <div class="font-weight-bold">
          <v-toolbar-title v-if="$route.name === 'events'">{{ hubbdata.home.labels.events }}</v-toolbar-title>
          <v-toolbar-title v-else-if="$route.name === 'organisations'">{{
            hubbdata.home.labels.organisations
          }}</v-toolbar-title>
          <v-toolbar-title v-else-if="$route.name === 'opportunities'">{{
            hubbdata.home.labels.apprenticeships
          }}</v-toolbar-title>
          <v-toolbar-title v-else-if="$route.name === 'contact'">{{ hubbdata.home.labels.contact }}</v-toolbar-title>
          <v-toolbar-title v-else-if="$route.name === 'infoandadvice'">{{ hubbdata.home.labels.info }}</v-toolbar-title>
          <v-toolbar-title v-else-if="$route.name === 'legal'">{{ hubbdata.home.labels.legal }}</v-toolbar-title>
          <v-toolbar-title v-else-if="$route.name === 'favourites'">{{ hubbdata.favourites.title }}</v-toolbar-title>
          <v-toolbar-title v-else-if="$route.name === 'cms_page'"></v-toolbar-title>
        </div>

        <ul v-if="$route.name === 'chat_single'" class="users-list">
          <template v-for="user in currentUsers">
            <li v-if="user.id != currentUser.id" :key="user.id" class="users-list__item">
              {{ user.display_name }}
            </li>
          </template>
        </ul>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        icon
        large
        @click="openFavourites"
        color="green"
        id="favourites-btn"
        v-if="favouritesCount > 0"
        :aria-label="hubbdata.accessibility ? hubbdata.accessibility.favourites : ''"
      >
        <span class="class sr-only">Open Favourites page</span>

        <v-badge :content="favouritesCount" color="primary" overlap class="pink--text" offset-x="42px" offset-y="35px">
          <svg width="41" height="35" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
            <title>Heart Icon</title>
            <path
              d="M18.6233 6.46408L20.3488 9.4087L22.0744 6.46408C23.6448 3.78427 26.6275 2 29.8874 2C32.4928 2 34.6524 3.00183 36.1767 4.67838C37.7124 6.36739 38.6977 8.8508 38.6977 11.9375C38.6977 14.7148 37.7459 16.877 35.8294 19.1371C33.8554 21.4649 30.9694 23.7743 27.1277 26.8484L26.9787 26.9677L26.9785 26.9678L26.9776 26.9686C25.0053 28.5466 22.7863 30.3219 20.3488 32.3775C17.911 30.3215 15.6918 28.546 13.7192 26.9678C13.7191 26.9678 13.719 26.9677 13.719 26.9677L13.5698 26.8483C9.72822 23.7742 6.84223 21.4648 4.8683 19.137C2.95181 16.877 2 14.7147 2 11.9374C2 8.85077 2.98531 6.36737 4.52098 4.67837C6.04531 3.00183 8.20492 2 10.8103 2C14.0702 2 17.0529 3.78427 18.6233 6.46408Z"
              fill="#F694C1"
              stroke="#363844"
              stroke-width="4"
            />
          </svg>
        </v-badge>
      </v-btn>

      <v-btn
        icon
        large
        @click="openSearch"
        id="search-btn"
        class="ml-2 black--text"
        :aria-label="hubbdata.accessibility ? hubbdata.accessibility.search : ''"
      >
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
          <title>Search Icon</title>
          <path
            d="M33.5638 26.6167C32.5959 25.6488 29.5445 23.3957 26.5149 21.6513C26.4821 21.7005 26.4493 21.7497 26.4164 21.8044C29.8342 16.2976 29.1616 8.96428 24.3766 4.17932C18.7987 -1.39311 9.75927 -1.39311 4.18137 4.17932C-1.39653 9.76269 -1.39106 18.7967 4.18137 24.3746C8.92259 29.1104 16.1574 29.8268 21.6479 26.5128C23.2283 29.258 25.5305 32.479 26.6187 33.5672C28.5327 35.4812 31.6498 35.4758 33.5638 33.5618C35.4778 31.6423 35.4778 28.5361 33.5638 26.6167ZM20.6144 20.6122C17.1145 24.1066 11.4491 24.1066 7.94924 20.6068C4.45485 17.1124 4.44939 11.447 7.94924 7.94714C11.4436 4.45275 17.1145 4.45275 20.6089 7.94714C24.1087 11.447 24.1087 17.1179 20.6144 20.6122Z"
            fill="#363844"
          />
        </svg>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      app
      class="toolbar toolbar--side"
      v-model="drawer"
      :fixed="$vuetify.breakpoint.smAndDown"
      :temporary="$vuetify.breakpoint.smAndDown"
      :permanent="$vuetify.breakpoint.mdAndUp"
    >
      <button
        @click="toggleDrawer"
        class="close-menu-btn hidden-md-and-up rounded-pill"
        type="button"
        :aria-label="hubbdata.accessibility ? hubbdata.accessibility.closeMenu : ''"
      >
        <svg
          width="35"
          height="35"
          style="enable-background: new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          role="img"
        >
          <title>Close Icon</title>
          <path
            d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"
            fill="#ffffff"
          />
        </svg>
      </button>

      <div class="navigation-drawer-image">
        <div class="navigation-drawer-image__logo-wrapper">
          <v-img
            alt="Ealing Cares Logo"
            contain
            class="navigation-drawer-image__logo"
            src="@/assets/ealing/images/ealing-logo.png"
          />
        </div>
      </div>

      <v-list nav dense>
        <v-list-item-group active-class="primary--text text--accent-4 " aria-label="Navigation Items" role="navigation">
          <v-list-item id="first-item" :to="{ name: 'home' }">
            <i class="mdi mdi-checkbox-blank"></i>

            <v-list-item-title>
              {{ hubbdata.tabs.home }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'infoandadvice' }">
            <i class="mdi mdi-checkbox-blank"></i>

            <v-list-item-title>
              {{ hubbdata.home.labels.info }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'organisations' }">
            <i class="mdi mdi-checkbox-blank"></i>

            <v-list-item-title>
              {{ hubbdata.home.labels.organisations }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'events' }" data-cypress-value="events-page">
            <i class="mdi mdi-checkbox-blank"></i>

            <v-list-item-title>
              {{ hubbdata.home.labels.events }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'education_training_work' }" data-cypress-value="opportunities-page">
            <i class="mdi mdi-checkbox-blank"></i>

            <v-list-item-title>
              {{ hubbdata.home.labels.educationTrainingWork }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'vips' }" data-cypress-value="vips-page">
            <i class="mdi mdi-checkbox-blank"></i>

            <v-list-item-title>
              {{ hubbdata.viplist.title }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'favourites' }" data-cypress-value="favourites-page">
            <i class="mdi mdi-checkbox-blank"></i>

            <v-list-item-title>
              {{ hubbdata.favourites.title }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'chats' }">
            <i class="mdi mdi-checkbox-blank"></i>

            <v-list-item-title>
              {{ hubbdata.home.labels.messaging
              }}<span v-if="isTokenSet && totalUnreadMessages" class="messages-notification">
                <v-icon color="primary" large class="pa-0">mdi-chat</v-icon>
                <span class="messages-notification__count">{{ totalUnreadMessages }}</span>
              </span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'posts' }">
            <i class="mdi mdi-checkbox-blank"></i>

            <v-list-item-title>
              {{ hubbdata.home.labels.posts }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'surveys' }">
            <i class="mdi mdi-checkbox-blank"></i>

            <v-list-item-title>
              {{ hubbdata.home.labels.surveys }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item link :to="{ name: 'contact' }" data-cypress-value="contact-page">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.contact }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'legal' }" data-cypress-value="legal-page">
            <i class="mdi mdi-checkbox-blank"></i>
            <v-list-item-title>
              {{ hubbdata.home.labels.legal }}
            </v-list-item-title>
          </v-list-item>

          <Settings />
        </v-list-item-group>

        <v-list-item
          style="flex-direction: column; width: 100%; margin-top: 30px; flex-flow: wrap"
          class="settings_language-selector"
        >
          <v-list-item-title>
            <label style="font-weight: 500; text-transform: none; color: $primary" id="languageSelect">{{
              hubbdata.settings.text.languages
            }}</label>
          </v-list-item-title>

          <v-select
            v-model="selectedLang"
            :items="langs"
            style="padding-top: 10px"
            menu-props="auto"
            name="langs"
            prepend-icon-color="primary"
            item-text="name"
            item-value="code"
            @change="updateLang(selectedLang)"
            solo
            :aria-label="hubbdata.settings.text.languages"
          >
          </v-select>
        </v-list-item>

        <v-list-item>
          <v-btn
            block
            color="primary"
            class="tour-restart font-family-cocon-pro font-weight-bold text-none white--text px-4"
            @click="restartTheTour"
            v-html="hubbdata.tutorial.startTutorial"
          ></v-btn>
        </v-list-item>

        <div v-if="isTokenSet" class="sign-out px-2 py-4">
          <v-divider />

          <v-btn
            block
            color="primary"
            class="font-family-cocon-pro font-weight-bold text-none white--text px-4"
            @click="logoutBtnClickHandler"
          >
            Logout
          </v-btn>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import chat from "@/chat";
import hubbdata from "@/hubbdata";
import favourites from "@/favourites";
import Settings from "@/components/Header/Settings.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Settings,
  },
  name: "Header",
  data: () => ({
    drawer: false,
    selectedLang: localStorage.getItem("current_lang"),
    loading: false,
    alert: false,
    dialog: false,
    fontAlert: false,
    openDyslexic: false,
    langs: [
      { name: "English", code: "en" },
      { name: "Albanian", code: "sq" },
      { name: "Amharic", code: "am" },
      { name: "Arabic", code: "ar" },
      { name: "Kurdish", code: "ku" },
      { name: "Kurdish (Sorani)", code: "ckb" },
      { name: "Oromo", code: "om" },
      { name: "Pashto", code: "ps" },
      { name: "Persian", code: "fa" },
      { name: "Punjabi", code: "pa" },
      { name: "Russian", code: "ru" },
      { name: "Somali", code: "so" },
      { name: "Tigrinya", code: "ti" },
      { name: "Urdu", code: "ur" },
      { name: "Vietnamese", code: "vi" },
    ],
  }),
  computed: {
    ...mapGetters("user", ["isTokenSet", "totalUnreadMessages"]),
    currentUser() {
      return this.$store.state.user;
    },
    favouritesCount() {
      console.log(favourites.getters.favouritesCount());
      return favourites.getters.favouritesCount();
    },
    currentLang() {
      let dictionary = {
        en: "English",
        sq: "Albanian",
        am: "Amharic",
        ar: "Arabic",
        ku: "Kurdish",
        ckb: "Kurdish (Sorani)",
        om: "Oromo",
        ps: "Pashto",
        fa: "Persian",
        pa: "Punjabi",
        ru: "Russian",
        so: "Somali",
        ti: "Tigrinya",
        ur: "Urdu",
        vi: "Vietnamese",
      };

      return dictionary[this.selectedLang];
    },
    currentUsers() {
      // return chat.state.users;
      return [];
    },
    // current_conversation_name() {
    //     return chat.state.current_conversation.name;
    // },
    current_cms_page() {
      return hubbdata.state.current_cms_page;
    },
    hubbdata() {
      return hubbdata.getters.translations;
    },
    conversations() {
      // return chat.state.all_conversations;
      return [];
    },
    messageCount() {
      let result = 0;
      for (let i = 0; i < this.conversations.length; i++) {
        result += this.conversations[i].unread_message_count;
      }
      console.log(result);
      return result;
    },
  },
  methods: {
    ...mapActions("user", ["logoutUser"]),
    restartTheTour() {
      this.drawer = !this.drawer;
      localStorage.removeItem("has_seen_the_tour");
      this.$router.push({ name: "home" });
      this.$tours["myTour"].start();
    },
    signOut() {
      this.$store.dispatch("signOut").then(() => {
        this.$router.push({ name: "user_sign_in" });
      });
    },
    toggleDrawer() {
      this.drawer = !this.drawer;

      setTimeout(selectMenuItem, 100);

      function selectMenuItem() {
        if (document.getElementsByClassName("v-list-item--active").length) {
          document.getElementsByClassName("close-menu-btn")[0].focus();
        } else {
          document.getElementById("first-item").focus();
        }
      }
    },
    loadingSpinner() {
      this.loading = !this.loading;
    },
    openSearch() {
      this.$router.push({ name: "search" });
    },
    openFavourites() {
      this.$router.push({ name: "favourites" });
    },
    goHome() {
      this.$router.push({ name: "home" });
    },
    openChats() {
      this.$router.push({ name: "chats" });
    },
    updateFont(font) {
      localStorage.setItem("enable_font_change", font);
      console.log(font);
      hubbdata.dispatch("updateFont", font);
      this.fontAlert = true;
    },
    updateLang(selectedLang) {
      this.loadingSpinner();
      hubbdata.dispatch("updateLang", selectedLang).then(() => {
        hubbdata.dispatch("initDB").then(() => {});
      });
      this.alert = true;
      // send a global emit back to Settings.vue with the newly selected language
      this.$bus.emit("language-changed-from-header", this.selectedLang);
    },
    DismissAlert() {
      this.alert = false;
    },
    DismissFontAlert() {
      this.fontAlert = false;
    },
    logoutBtnClickHandler() {
      this.logoutUser().then(() => {
        this.$router.push({ name: "sign_in" });
      });
    },
  },
  mounted() {
    if (localStorage.getItem("enable_font_change") === "true") {
      this.openDyslexic = true;
    } else {
      this.openDyslexic = false;
    }
    /// listening for language change emit coming form settings.vue
    /// we're passing the 'language' and then setting is as a selected one
    this.$bus.$on("language-changed-from-settings-page", (language) => {
      this.selectedLang = language;
    });
  },
  beforeDestroy() {
    // stop listening for the emit coming from settings.vue
    this.$bus.$off("language-changed-from-settings-page");
  },
};
</script>

<style lang="scss">
.tour-restart {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  text-transform: lowercase;
}

.toolbar .v-badge__badge {
  font-weight: 600;
  font-size: 14px;
  color: $pink !important;
}
</style>

<style lang="scss" scoped>
.v-navigation-drawer--fixed {
  height: 100% !important;
}

.v-navigation-drawer {
  overflow: unset;

  ::v-deep .v-navigation-drawer__content {
    overflow: auto;
  }
}

.close-menu-btn {
  position: absolute;
  left: 100%;
  margin: 8px 0 0 10px;
  padding: 2px;
  line-height: 0;
}

a:focus {
  outline: 3px solid $primary;
}

.v-alert {
  margin: 10px 0px 10px 10px !important;
  margin-left: 0px !important;
  width: 100%;
}

.v-list {
  padding-bottom: 69px;
}

.v-list-item__title {
  font-size: 16px !important;
  text-align: left;
}

.home__headline-text {
  text-align: center;
  padding: 50px 10px 0px 10px;
  font-size: 35px;
  line-height: 0px;
  color: black !important;
  font-family: "Lato-Regular" !important;
}

.users-list {
  padding-left: 0;
  display: flex;
  list-style: none;
  font-size: 11px;

  &__item {
    padding: 4px;
  }
}

.back-button {
  margin-right: 10px;
  margin-left: 10px;
  text-transform: capitalize;
}

.banner {
  margin-top: 57px;
  font-size: 12px;
  text-align: center;
  //   color: black !important;
}

.v-list-item__title a,
.v-list-item__title,
.v-input,
label {
  color: black !important;
  text-decoration: none;
  font-size: 15px;
  line-height: 20px !important;
}

.label {
  margin: 10px !important;
}

i {
  color: $primary;
  font-size: 10px;
  padding-right: 10px;
}

.v-input {
  border-radius: 0px;
}

.navigation-drawer-image {
  height: 100px;
  background: $primary;
  border-bottom: 5px solid $primary;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 70px;
    height: 74px;
    overflow: hidden;
  }

  &__logo {
    width: 100%;
    height: auto;
  }

  &__title {
    font-family: "ff-cocon-pro", sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.17;
    margin-top: auto;
    margin-bottom: 9px;
  }
}

.sign-out {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $green;
}

.messages-notification {
  position: relative;
  margin-left: 10px;

  &__count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1;
    color: $white;
    padding-bottom: 3px;
  }
}
</style>
