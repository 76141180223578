<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs" v-on="on">
        <v-list-item color="red lighten-2" dark>
          <i class="mdi mdi-checkbox-blank"></i>
          <v-list-item-title>
            {{ hubbdata.settings.title }}
          </v-list-item-title>
        </v-list-item>
      </a>
    </template>

    <v-card>
      <v-toolbar>
        <v-btn icon dark @click="dialog = false">
          <v-icon color="primary">mdi-close</v-icon>
          <span class="sr-only">{{ hubbdata.settings.text.close }}</span>
        </v-btn>

        <v-toolbar-title>{{ hubbdata.settings.title }}</v-toolbar-title>
      </v-toolbar>

      <v-container class="px-4 px-sm-8 py-6">
        <v-list class="v-list--settings-page pa-0">
          <v-list-item class="flex-column align-start pa-0 mb-8">
            <v-list-item-title>
              <label for="fonts" id="languageSelect" class="white--text">{{
                hubbdata.settings.text.changedefault
              }}</label>
            </v-list-item-title>

            <v-switch
              v-model="openDyslexic"
              name="fonts"
              class="d-block w-100"
              width="100%"
              :label="`OpenDyslexic`"
              @change="updateFont(openDyslexic)"
              solo
            />
          </v-list-item>

          <v-list-item
            class="flex-column align-start pa-0 settings_language-selector"
          >
            <v-list-item-title>
              <label for="langs" id="languageSelect" class="white--text">{{
                hubbdata.settings.text.languages
              }}</label>
            </v-list-item-title>

            <v-select
              v-model="selectedLang"
              :items="langs"
              style="padding-top: 10px"
              menu-props="auto"
              name="langs"
              prepend-icon-color="primary"
              item-text="name"
              item-value="code"
              @change="updateLang(selectedLang)"
              solo
            />
          </v-list-item>
        </v-list>

        <v-alert
          close-text="Close Alert"
          color="#dee0e2"
          rounded="false"
          dismissible
          icon="mdi-alert-circle"
          class="banner settings_page-alerts text-primary"
          light
          @input="DismissFontAlert()"
          v-if="fontAlert"
        >
          <span v-if="openDyslexic">{{ hubbdata.settings.text.textopen }}</span>
          <span v-else>{{ hubbdata.settings.text.textdefault }}</span>
        </v-alert>

        <v-alert
          close-text="Close Alert"
          color="#dee0e2"
          rounded="false"
          dismissible
          icon="mdi-alert-circle"
          class="banner settings_page-alerts"
          light
          @input="DismissAlert()"
          v-if="alert"
        >
          {{ hubbdata.settings.text.languageset }} {{ currentLang }}
        </v-alert>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import hubbdata from "@/hubbdata";

export default {
  name: "Settings",
  data: () => ({
    drawer: false,
    selectedLang: localStorage.getItem("current_lang"),
    dialog: false,
    fontAlert: false,
    alert: false,
    openDyslexic: false,
    langs: [
      { name: "English", code: "en" },
      { name: "Albanian", code: "sq" },
      { name: "Amharic", code: "am" },
      { name: "Arabic", code: "ar" },
      { name: "Kurdish", code: "ku" },
      { name: "Kurdish (Sorani)", code: "ckb" },
      { name: "Oromo", code: "om" },
      { name: "Pashto", code: "ps" },
      { name: "Persian", code: "fa" },
      { name: "Punjabi", code: "pa" },
      { name: "Russian", code: "ru" },
      { name: "Somali", code: "so" },
      { name: "Tigrinya", code: "ti" },
      { name: "Urdu", code: "ur" },
      { name: "Vietnamese", code: "vi" },
    ],
  }),
  mounted() {
    console.log(localStorage.getItem("enable_font_change"));
    if (localStorage.getItem("enable_font_change") === "true") {
      this.openDyslexic = true;
    } else {
      this.openDyslexic = false;
    }

    /// listening for language change emit coming form Header.vue
    /// we're passing the 'language' and then setting is as a selected one
    this.$bus.$on("language-changed-from-header", (language) => {
      this.selectedLang = language;
    });
  },
  beforeDestroy() {
    // stop listening for the emit coming from Header.vue
    this.$bus.$off("language-changed-from-header");
  },
  methods: {
    updateLang(selectedLang) {
      hubbdata.dispatch("updateLang", selectedLang).then(() => {
        hubbdata.dispatch("initDB").then(() => {});
      });
      this.alert = true;
      // send a global emit back to Header.vue with the newly selected language
      this.$bus.emit("language-changed-from-settings-page", this.selectedLang);
    },
    updateFont(font) {
      localStorage.setItem("enable_font_change", font);
      console.log(font);
      hubbdata.dispatch("updateFont", font);
      this.fontAlert = true;
    },
    DismissAlert() {
      this.alert = false;
    },
    DismissFontAlert() {
      this.fontAlert = false;
    },
  },
  computed: {
    currentLang() {
      let dictionary = {
        en: "English",
        sq: "Albanian",
        am: "Amharic",
        ar: "Arabic",
        ku: "Kurdish",
        ckb: "Kurdish (Sorani)",
        om: "Oromo",
        ps: "Pashto",
        fa: "Persian",
        pa: "Punjabi",
        ru: "Russian",
        so: "Somali",
        ti: "Tigrinya",
        ur: "Urdu",
        vi: "Vietnamese",
      };

      return dictionary[this.selectedLang];
    },
    hubbdata() {
      return hubbdata.getters.translations;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  background-color: $primary;
}

.v-list {
  &--settings-page {
    background-color: $primary;
    .v-list-item {
      &:after {
        display: none;
      }

      &__title {
        align-self: flex-start;
      }
    }
  }
}

.v-input {
  ::v-deep {
    .v-label {
      color: $white !important;
    }
    .v-messages {
      display: none;
    }

    .v-input--switch__track {
      color: $grey !important;
    }

    .v-input--switch__track {
      color: $white !important;
    }
  }
}

.v-navigation-drawer__content {
  padding-top: 40px !important;
}

a:focus {
  outline: 3px solid black;
}

.v-alert {
  margin: 10px 0px 10px 10px !important;
  margin-left: 0px !important;
  width: 100%;
}

.v-list-item__title {
  font-size: 16px !important;
  text-align: left;
}

.banner {
  font-size: 16px;
  text-align: center;

  ::v-deep {
    .v-icon {
      color: $primary !important;
    }
  }
}

.v-badge__badge {
  color: black !important;
}

.v-list-item__title a,
.v-list-item__title,
.v-input,
label {
  color: black !important;
  text-decoration: none;
  font-size: 15px;
  line-height: 20px !important;
}

label {
  color: #1d1d1d !important;
}

.label {
  margin: 10px !important;
}

i {
  color: black;
  font-size: 10px;
  padding-right: 10px;
}

.v-input {
  border-radius: 0px;
}

.v-toolbar {
  &__title {
    font-weight: 700;
  }
}
</style>
